import axios from "axios";

const API_KEY = "AIzaSyABAKjcnWFFuPN75oVZJu44dKcAZv_HNK8";


export const obtenerLatLong = async (direccion, ciudad) => {
  const direccionCompleta = `${direccion}, ${ciudad}`;  // Combinas la dirección y la ciudad
  const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(direccionCompleta)}&key=${API_KEY}`; 

  try {
    const response = await axios.get(url);
    console.log("Respuesta de la API:", response); // Verifica la respuesta completa de la API
    if (response.data.status === "OK") {
      const { lat, lng } = response.data.results[0].geometry.location;
      return { lat, lng };
    } else {
      throw new Error("Dirección no encontrada");
    }
  } catch (error) {
    throw new Error("Error obteniendo latitud y longitud: " + error.message);
  }
};

