import { useEffect, useState } from "react"
import { getCalculoEnvio } from "../utils/apiEnvios"

const useCalculoEnvio = (cart, codCiudad) => {
  const [costoEnvio, setCostoEnvio] = useState(0)
  const [tarifasEnvio, setTarifasEnvio] = useState("")

  useEffect(() => {
    const calcularEnvio = async () => {
      if (!codCiudad) return

      const productDataToSend = cart.map((item) => ({
        codProducto: item.codProducto,
        codVendedor: item.codVendedor,
        cantidad: item.cantidad,
        altProducto: item.altProducto / 100,
        anchoProducto: item.anchoProducto / 100 ,
        lonProducto: item.lonProducto / 100 ,
        peso: item.peso,
        precioProducto: item.precioRebajado || item.precioProducto,
      }))

      console.log(productDataToSend)
      const data = {
        productos: productDataToSend,
        codCiudadDestino: codCiudad,
      }

      try {
        const response = await getCalculoEnvio(data)
        setCostoEnvio(response.valTotal)
        setTarifasEnvio(response)
      } catch (error) {
        console.error("Error al calcular el envío:", error)
        setCostoEnvio(0)
      } 
    }

    calcularEnvio()
  }, [cart, codCiudad])

  return  { costoEnvio, tarifasEnvio }
}

export default useCalculoEnvio



