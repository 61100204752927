export const handleEpaycoPayment = (
  numPedido,
  total,
  direccion,
  productos,
  nomCliente,
  apeCliente,
  telefono,
  identificacion, 
  costoEnvio
) => {


  const productName =
    productos.length > 1
      ? productos.map((producto) => producto.nomProducto).join(" - ")
      : productos[0].nomProducto




  const receptoresProductos = productos.map((producto) => ({
    id: producto.idEpayco,
    total: String((producto.precioRebajado || producto.precioProducto) * producto.cantidad),  // Convertimos el total a string
    iva: "",
    base_iva: "", 
    fee: producto.fee, 
  }))

  const receptorEnvio = {
    id: "1449848" ,
    total: costoEnvio,
    iva: "",
    base_iva: "", 
    fee: "0", 

  }

  const receptores = [...receptoresProductos, receptorEnvio]




  const data = {
    name: productName,
    description: productName,
    invoice: numPedido,
    currency: "cop",
    amount: total,
    tax_base: "0",
    tax: "0",
    country: "co",
    lang: "es",
    split_app_id: "1449848",
    split_merchant_id: "1449848",
    split_type: "02",
    split_primary_receiver: "1449848",
    split_primary_receiver_fee: "0",
    splitpayment: "true",
    split_rule: "multiple",
    // split_receivers: [
    //   { id: "1455913", total: total, iva: "0", base_iva: "0", fee: "2" },
    //   // { id: "93006", total: "3", iva: "", base_iva: "", fee: "1" }
    // ],
    split_receivers: receptores,
    external: "false",
    confirmation: "https://devshopapi.encarga.app/confirmation.php",
    response: "https://shop.encargasas.com/response-compra/",
    //response: "http://localhost:3000/response-compra/",
    name_billing: nomCliente + " " + apeCliente,
    address_billing: direccion,
    type_doc_billing: "cc",
    mobilephone_billing: telefono,
    number_doc_billing: identificacion,
  }

  window.ePaycoCheckout.open(data)
  console.log("data", data)
}
