import React, { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { toast, ToastContainer } from "react-toastify"

import Pagos from "./Pagos"
import { useCartContext } from "../../contexts/CartContext"
import { useUserContext } from "../../contexts/UserContext"
import { realizarPedido } from "../../utils/apiCheckout"
import { useFetchDptos } from "../../hooks/useFetchDptos"
import { useFetchCitys } from "../../hooks/useFetchCitys"
import {
  useFetchTipoPersona,
  useFetchTiposDocumentos,
} from "../../hooks/useFetchTypeDocument"

import { handleEpaycoPayment } from "../../helpers/handleEpaycoPayment"
import { obtenerLatLong } from "../../utils/apiGoogleMaps"
import { useFetchNomCiud } from "../../hooks/useFetchNomCiud"

const FormCheckout = ({
  onCityChange,
  costoEnvio,
  tarifasEnvio,
  totalAPagar,
}) => {
  const navigate = useNavigate()
  const [userData] = useState(JSON.parse(localStorage.getItem("userData")))

  const [codDepto, setCodDepto] = useState(userData?.codDepto ?? "")

  const { isLoggedIn } = useUserContext()
  const listaCiudades = useFetchCitys(codDepto)
  const listaDepartamentos = useFetchDptos()
  const listaTipoDocumento = useFetchTiposDocumentos()
  const listaTipoPersona = useFetchTipoPersona()


  const { cart, cartTotalConDescuento, clear } = useCartContext()

  const [email, setEmail] = useState(userData?.emaUsuario ?? "")
  const [isValidEmail, setIsValidEmail] = useState(true)
  const [nombre, setNombre] = useState(userData?.nomUsuario ?? "")
  const [apellidos, setApellidos] = useState(userData?.apeUsuario ?? "")
  const [identificacion, setIdentificacion] = useState(
    userData?.idUsuario ?? ""
  )
  const [telefono, setTelefono] = useState(userData?.celUsuario ?? "")
  const [terminos, setTerminos] = useState("")
  const [direccion, setDireccion] = useState(userData?.dirUsuario ?? "")
  const [latitud, setLatitud] = useState(null)
  const [longitud, setLongitud] = useState(null)
  const [error, setError] = useState("")
  const [debouncedDireccion, setDebouncedDireccion] = useState(direccion);

  const [codCiudad, setCodCiudad] = useState(userData?.ciuUsuario ?? "")

  const [codTipPersona, setCodTipPersona] = useState(
    userData?.codTipPersona ?? ""
  )
  const [codTipDocumento, setCodTipDocumento] = useState(
    userData?.codTipContribuyente ?? ""
  )
  const [metodoPago, setMetodoPago] = useState("")
  const [pedido, setPedido] = useState({})

  const [showModal, setShowModal] = useState(false)

  const validateEmail = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(email)
  }

  const handleEmailChange = (e) => {
    setEmail(e.target.value)
    setIsValidEmail(true)
  }


  const nombreCiudad = useFetchNomCiud(codCiudad, codDepto)

// Actualiza la dirección después de un pequeño retraso (debounce)
useEffect(() => {
  const timeoutId = setTimeout(() => {
    setDebouncedDireccion(direccion);
  }, 1000); // Espera 1 segundo para hacer la llamada a la API

  return () => clearTimeout(timeoutId); // Limpiar el timeout si cambia la dirección
}, [direccion]);

useEffect(() => {
  if (debouncedDireccion && nombreCiudad) {
    // Combina la dirección y la ciudad
    const direccionCompleta = `${debouncedDireccion}, ${nombreCiudad}`;
    console.log("Buscando latitud y longitud para la dirección:", direccionCompleta);

    obtenerLatLong(direccionCompleta)  // Pasa la dirección combinada y ciudad
      .then(({ lat, lng }) => {
        setLatitud(lat);
        setLongitud(lng);
        setError(""); // Limpiar error si la solicitud es exitosa
      })
      .catch((err) => {
        setError("Error al obtener latitud y longitud: " + err.message);
        setLatitud(null);
        setLongitud(null);
      });
  }
}, [debouncedDireccion, nombreCiudad]); // Se ejecuta cada vez que cambie la dirección o la ciudad

  
  useEffect(() => {
    console.log("Latitud:", latitud, "Longitud:", longitud)
  }, [latitud, longitud])

  const handleSubmit = async (e) => {
    e.preventDefault()

    const emailIsValid = validateEmail()
    setIsValidEmail(emailIsValid)

    if (emailIsValid) {
      try {
        const formData = new FormData()
        formData.append("emaCliente", email)
        formData.append("nomCliente", nombre)
        formData.append("apeCliente", apellidos)
        formData.append("ideCliente", identificacion)
        formData.append("codCiudad", codCiudad)
        formData.append("dirCliente", direccion)
        formData.append("latitud", latitud)
        formData.append("longitud", longitud)
        formData.append("telCliente", telefono)
        formData.append("codMetpago", metodoPago)
        formData.append("cart", JSON.stringify(cart))
        formData.append("subTotal", cartTotalConDescuento)
        formData.append("costoEnvio", costoEnvio)
        formData.append("tarifasEnvio", JSON.stringify(tarifasEnvio))
        formData.append("total", totalAPagar)
        formData.append("codTipPersona", codTipPersona)
        formData.append("codTipDocumento", codTipDocumento)
        formData.append("codUsuario", isLoggedIn ? userData.codUsuario : 0)
        formData.append("codCliente", isLoggedIn ? userData.codCliente : 0)

        const response = await realizarPedido(formData)

        setPedido(response.data)

        if (response.data) {
          const {
            numPedido,
            codMetpago,
            productos,
            nomCliente,
            apeCliente,
            email,
            total,
            identificacion,
            telefono,
            costoEnvio,
          } = response.data

          if (codMetpago === "1") {
            handleEpaycoPayment(
              numPedido,
              total,
              direccion,
              productos,
              nomCliente,
              apeCliente,
              telefono,
              identificacion,
              costoEnvio
            )
            // } else if (codMetpago === "2") {
            //   handlePayUPayment(numPedido, email, total, direccion, codCiudad)
            // } else if (codMetpago === "3") {
            //   navigate(`/confirmacion-compra?pedidoId=${response.data.numPedido}`)

            //
          }
        }

        clear()
      } catch (error) {
        console.error(error)
      }
    }
  }

  useEffect(() => {
    if (window.ePayco) {
      window.ePaycoCheckout = window.ePayco.checkout.configure({
        key: "83e07c6c47ee847091ec26d7b98bd728",
        test: true, // Cambia a false en producción
      })
    }
  }, [])

  const handleDeptoChange = (e) => {
    const newDepto = e.target.value
    setCodDepto(newDepto)
    setCodCiudad("") // Reiniciar la ciudad seleccionada cuando se cambia el departamento
    onCityChange("") // Notificamos al componente padre que se ha reiniciado la selección de ciudad
  }

  const handleCityChange = (e) => {
    const newCity = e.target.value
    setCodCiudad(newCity)
    onCityChange(newCity)
  }

  return (
    <div className="pr-4  pb-10 md:pr-20">
      <form className="mt-4" onSubmit={handleSubmit}>
        <div className="grid grid-cols-2 gap-2">
          <div className="mb-4 ">
            <div className="flex space-x-1 items-center ">
              <label
                htmlFor="nombre"
                className="text-sm text-gray-500 mb-1 pl-2"
              >
                Nombre
              </label>
            </div>

            <input
              type="text"
              id="nombre"
              className="border border-gray-300  p-2 mt-1 w-full"
              value={nombre}
              onChange={(e) => setNombre(e.target.value)}
              required
            />
          </div>
          <div className="mb-4 ">
            <div className="flex space-x-1 items-center ">
              <label
                htmlFor="apellido"
                className="text-sm text-gray-500 mb-1 pl-2"
              >
                Apellidos
              </label>
            </div>

            <input
              type="text"
              id="apellidos"
              className="border border-gray-300  p-2 mt-1 w-full"
              value={apellidos}
              onChange={(e) => setApellidos(e.target.value)}
              required
            />
          </div>
        </div>
        <div className="grid grid-cols-2 gap-2">
          <div className="mb-4 ">
            <div className="flex space-x-1 items-center ">
              <label
                htmlFor="email"
                className="text-sm text-gray-500 mb-1 pl-2"
              >
                Correo Eléctronico
              </label>
            </div>

            <input
              type="email"
              id="email"
              className={`border border-gray-300  p-2 mt-1 w-full ${
                !isValidEmail && "border-red-500"
              }`}
              value={email}
              onChange={handleEmailChange}
              required
            />
            {!isValidEmail && (
              <p className="text-red-500 text-sm mt-1">
                Por favor, ingresa un correo electrónico válido.
              </p>
            )}
          </div>
          <div className="mb-4">
            <label
              htmlFor="telefono"
              className="text-sm text-gray-500 mb-1 pl-2 mt-6"
            >
              Teléfono
            </label>
            <input
              type="text"
              id="telefono"
              className="border border-gray-300  p-2 mt-1 w-full"
              value={telefono}
              onChange={(e) => setTelefono(e.target.value)}
              required
            />
          </div>
        </div>

        <div className="grid grid-cols-2 gap-2">
          <div className="mb-4">
            <label
              htmlFor="codDepto"
              className="text-sm text-gray-500 mb-1 pl-2 mt-6"
            >
              Departamento
            </label>

            <select
              name="codDepto"
              id="codDepto"
              className="border border-gray-300  p-3 mt-1 w-full text-sm"
              value={codDepto}
              onChange={handleDeptoChange}
              required
            >
              <option value="" disabled>
                --Seleccione--
              </option>
              {listaDepartamentos.map((departamento) => (
                <option
                  key={departamento.codDepto}
                  value={departamento.codDepto}
                >
                  {departamento.nomDepto}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-4">
            <label
              htmlFor="codCiudad"
              className="text-sm text-gray-500 mb-1 pl-2 mt-6"
            >
              Ciudad
            </label>

            <select
              name="codCiudad"
              id="codCiudad"
              className="border border-gray-300  p-3 mt-1 w-full text-sm"
              value={codCiudad}
              onChange={handleCityChange}
              required
              disabled={!codDepto}
            >
              <option value="" disabled>
                --Seleccione--
              </option>
              {listaCiudades.map((ciudad) => (
                <option key={ciudad.codCiudad} value={ciudad.codCiudad}>
                  {ciudad.nomCiudad}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="mb-4">
          <label
            htmlFor="direccion"
            className="text-sm text-gray-500 mb-1 pl-2 mt-6"
          >
            Dirección
          </label>
          <input
            type="text"
            id="direccion"
            className="border border-gray-300  p-2 mt-1 w-full"
            value={direccion}
            onChange={(e) => setDireccion(e.target.value)}
            required
          />
        </div>
        <div className="grid grid-cols-2 gap-2">
          <div className="mb-4 ">
            <label
              htmlFor="codTipPersona"
              className="text-sm text-gray-500 mb-1 pl-2 mt-6"
            >
              Tipo de Persona
            </label>
            <select
              name="codTipPersona"
              id="codTipPersona"
              className="border border-gray-300  p-2 mt-1 w-full"
              value={codTipPersona}
              onChange={(e) => setCodTipPersona(e.target.value)}
              required
            >
              <option value="" disabled>
                Seleccionar Tipo de Persona
              </option>

              {listaTipoPersona.map((tipoPersona) => (
                <option
                  key={tipoPersona.codTipPersona}
                  value={tipoPersona.codTipPersona}
                >
                  {tipoPersona.nomTipPersona}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-4 ">
            <label
              htmlFor="tipoDocumento"
              className="text-sm text-gray-500 mb-1 pl-2 mt-6"
            >
              Tipo Documento
            </label>
            <select
              name="tipoDocumento"
              id="tipoDocumento"
              className="border border-gray-300  p-2 mt-1 w-full"
              value={codTipDocumento}
              onChange={(e) => setCodTipDocumento(e.target.value)}
              required
            >
              <option value="" disabled>
                Seleccionar Tipo de Documento
              </option>

              {listaTipoDocumento.map((tipoDocumento) => (
                <option
                  key={tipoDocumento.codTipo}
                  value={tipoDocumento.codTipo}
                >
                  {tipoDocumento.nomTipo}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="mb-4 ">
          <div className="flex space-x-1 items-center ">
            <label
              htmlFor="identificacion"
              className="text-sm text-gray-500 mb-1 pl-2"
            >
              Nº de identificación
            </label>
          </div>
          <input
            type="number"
            id="identificacion"
            className="border border-gray-300  p-2 mt-1 w-full"
            value={identificacion}
            onChange={(e) => setIdentificacion(e.target.value)}
            required
          />
        </div>
        <h2 className="font-bold uppercase text-primario mb-6 text-lg mt-6">
          PAGO
        </h2>
        <Pagos metodoPago={metodoPago} setMetodoPago={setMetodoPago} />

        <div className="mb-4 mt-10">
          <label className="items-center text-sm text-gray-500 mb-1  mt-10">
            <input
              type="checkbox"
              className="mr-2"
              required
              id="indAceptaterminos"
              value={terminos}
              onChange={(e) => setTerminos(e.target.value)}
            />
            Sus datos personales se utilizarán para procesar su pedido,
            respaldar su experiencia en este sitio web y para otros fines
            descritos en nuestra
            <a
              href="#"
              className="text-primary underline ml-2 mr-2 hover:text-secundario"
            >
              Política de privacidad.
            </a>
          </label>
        </div>
        <div className="flex justify-start">
          <button
            type="submit"
            className="bg-secundario p-3 px-8 text-white rounded-lg text-base font-medium mt-4"
            value="Enviar"
          >
            {" "}
            Pagar pedido
          </button>
        </div>
      </form>
    </div>
  )
}

export default FormCheckout
